"use client"

import { Button } from "@/components/ui/button";
import { calculateUserPrices, getUserPriceTexts } from "@/lib/price-utils";
import { ExtendedSubscriptionTemplate, ExtendedTeam, ExtendedUser, NewUser, TeamSubscriptionPrice } from "@/prisma/types";
import { useEffect, useState } from "react";

interface UpgradeConfirmationProps {
    newUsers: NewUser[];
    currentUser: ExtendedUser;
    team: ExtendedTeam;
    allSubscriptionTemplates: ExtendedSubscriptionTemplate[];
    teamSubscriptionPrices: TeamSubscriptionPrice[]
    buttonClicked: () => void;
}

export const UpgradeConfirmationComponent = ({ newUsers, currentUser, team, allSubscriptionTemplates, teamSubscriptionPrices, buttonClicked }: UpgradeConfirmationProps) => {
    const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined)

    useEffect(() => {
        const newTotalPrice = calculateUserPrices({ 
            newUsers: newUsers ?? [], 
            subscriptionTemplates: allSubscriptionTemplates, 
            team,
            teamSubscriptionPrices }) ?? 0
        
        setTotalPrice(newTotalPrice)
    }, [newUsers])
    return (
        <div className="flex flex-col max-w-xl items-start py-10 h-full w-full">
            <div className="flex flex-col w-full pt-6 gap-2">
                    <h2 className="text-black">
                        Your Selected Subscriptions
                    </h2>
                    <div className="flex flex-col jus pt-4 gap-2">
                        {getUserPriceTexts({
                            users: newUsers,
                            subscriptionTemplates: allSubscriptionTemplates,
                            subscriptionDuration: team.subscriptionDuration
                        }).map((textLine, index) => (
                            <div key={index} className="flex flex-row justify-between">
                                <p className="text-xs text-black">{textLine.text}</p>
                                <p className="text-xs text-black">{textLine.price} €</p>
                            </div>
                        ))}
                    </div>
            </div>
            <div className="w-full flex flex-col justify-end items-end pt-6 pb-2">
                    <p className="text-muted-foreground text-xs mb-2">Total price for {team.subscriptionDuration} months</p>
                    <h1 className="font-recoleta text-4xl text-black">{totalPrice} €</h1>
                    <Button
                        disabled={newUsers === null || newUsers?.length === 0}
                        className="mt-4"
                        onClick={buttonClicked}>
                        Confirm Upgrade
                    </Button>
                </div>
        </div>
    )

}