"use client"

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Country, InvoiceInformation } from "@prisma/client";
import { useEffect, useState, } from "react";
import { getAllCountries } from "@/actions/dimensions/countries";
import { InviteTeamMembersForm } from "./add-team-members";
import { InvoiceInformationForm } from "./add-invoice-information";
import { UpgradeReceiptComponent } from "./upgrade-receipt";
import { ExtendedSubscriptionTemplate, ExtendedTeam, ExtendedUser, NewUser, TeamSubscriptionPrice } from "@/prisma/types";
import { filterTemplatesByDuration } from "@/lib/utils";
import { UpgradeConfirmationComponent } from "./upgrade-confirmation";


interface UpgradeDialogComponentProps {
    team: ExtendedTeam;
    currentUser: ExtendedUser;
    allSubscriptionTemplates: ExtendedSubscriptionTemplate[];
    teamSubscriptionPrices: TeamSubscriptionPrice[]
}

export const UpgradeDialogComponent = ({ team, currentUser, allSubscriptionTemplates, teamSubscriptionPrices } : UpgradeDialogComponentProps) => {

    const [countries, setCountries] = useState<Country[]>([]);
    const [newUsers, setNewUsers] = useState<NewUser[] | null>(null)
    const [invoiceInformation, setInvoiceInformation] = useState<any | null>(team.invoiceInformation)

    const [upgradeConfirmed, setUpgradeConfirmed] = useState<boolean>(true)

    const activeSubscriptionTemplates = filterTemplatesByDuration(allSubscriptionTemplates, team.subscriptionDuration)
    
    useEffect(() => {

        getAllCountries()
            .then((countries) => {
                setCountries(countries ?? []);
            })
    }, []);

    const invoiceInformationAdded = (invoiceInformation: InvoiceInformation) => {
        console.log("invoice information added")

        setInvoiceInformation(invoiceInformation)
    }

    const usersAdded = (users: NewUser[]) => {
        if (users.length === 0) {
            return;
        }

        console.log("users added: ", users)
        setNewUsers(users)
    }

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button size={"md"} variant={"darkOutline"}>
                    Upgrade
                </Button>
            </DialogTrigger>
            <DialogContent 
                className="w-full max-w-[1440px] max-h-[80%] overflow-scroll bg-neutral-100 flex flex-col items-center justify-center">

                {newUsers === null && (
                    <div className="flex flex-col max-w-2xl items-start py-10 h-full w-full">
                        {/* <h5 className="text-black"></h5> */}
                        <h2 className="font-recoleta text-black  text-3xl mb-4">Upgrade Now</h2>
                        <p className="text-black mb-10">Select a subscription plan for you and your team. You can invite more users to your team or assign their subscriptions now or any time.</p>
                        <InviteTeamMembersForm
                            className=""
                            currentUser={currentUser}
                            team={team}
                            confirmSubscriptionPlan={usersAdded}
                            teamSubscriptionPrices={teamSubscriptionPrices}
                            allSubscriptionTemplates={activeSubscriptionTemplates ?? []}/>
                    </div>
                ) || newUsers !== null && (invoiceInformation === null || invoiceInformation === undefined) && (
                    <div className="flex flex-col max-w-2xl items-start py-10 h-full w-full">
                        {/* <h5 className="text-black"></h5> */}
                        <h2 className="font-recoleta text-black  text-3xl mb-4">Invoice Information</h2>
                        <p className="text-black mb-10">Please fill out the invoice information below, so we know how to best invoice you.</p>
                        <InvoiceInformationForm
                            teamId={team.id}
                            countries={countries}
                            invoiceInformationAdded={invoiceInformationAdded} />
                    </div>

                ) || newUsers !== null && !upgradeConfirmed && (invoiceInformation !== null && invoiceInformation !== undefined) && (
                    <div className="flex flex-col max-w-2xl items-start py-10 h-full w-full">
                        {/* <h5 className="text-black"></h5> */}
                        <h2 className="font-recoleta text-black  text-3xl mb-4">Confirm Purchase</h2>
                        <p className="text-black mb-10">You are about to complete you upgrade to a paid CM Navigator Subscription.</p>
                        <UpgradeConfirmationComponent
                            newUsers={newUsers}
                            currentUser={currentUser}
                            team={team} 
                            allSubscriptionTemplates={activeSubscriptionTemplates ?? []}
                            teamSubscriptionPrices={teamSubscriptionPrices}
                            buttonClicked={() => {
                                setUpgradeConfirmed(true)
                            }}/>
                    </div>
                ) || newUsers !== null && upgradeConfirmed && (invoiceInformation !== null && invoiceInformation !== undefined) && (
                        <UpgradeReceiptComponent
                            newUsers={newUsers}
                            currentUser={currentUser}
                            team={team} 
                            allSubscriptionTemplates={activeSubscriptionTemplates ?? []}
                            buttonClicked={() => {
                                window.location.reload();
                            }}/>
                )}

            </DialogContent>
        </Dialog>
    );


    // if (user.team?.invoiceInformation === null || user.team?.invoiceInformation === undefined) {
    //     return (
    //         <Dialog>
    //             <DialogTrigger>
    //                 <Button size={"md"} variant={"darkOutline"}>
    //                     Upgrade
    //                 </Button>
    //             </DialogTrigger>
    //             <DialogContent className="w-full max-w-[1440px] max-h-[80%] overflow-scroll bg-neutral-100 flex flex-col items-center justify-center">
    //                 <div className="flex flex-col max-w-2xl items-start py-10 h-full">
    //                     <h5 className=" text-black">Invoice Information</h5>
    //                     <h2 className="font-recoleta text-black  text-3xl">CM Navigator</h2>
    //                     <p className=" text-black mb-10">To proceed with your upgrade, please fill out your invoicing information.</p>
    //                     <InvoiceInformationForm
    //                         teamId={user.team.id}
    //                         countries={countries}
    //                         invoiceInformationAdded={invoiceInformationAdded} />
    //                 </div>
    //             </DialogContent>
    //         </Dialog>
    //     )
    // }


}




