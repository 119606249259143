import(/* webpackMode: "eager", webpackExports: ["InternalNavigationMenu"] */ "/app/app/(protected)/(routes)/(external)/platform/_components/navbar/navigation-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UpgradeDialogComponent"] */ "/app/app/(protected)/(routes)/(external)/platform/_components/upgrade/upgrade-dialog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoutButton"] */ "/app/components/auth/logout-button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuGroup","DropdownMenuItem","DropdownMenuSeparator"] */ "/app/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.6_@babel+core@7.24.5_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
