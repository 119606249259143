"use client";

import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger,
    navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu"
import { cn } from "@/lib/utils"
import Link from "next/link"
import React from "react"


export type MenuItemComponentProps = {
    menuGroupName: string
    menuGroupItems: {
        title: string
        href: string
        description: string
    }[]

}

function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints ?? 0 > 0) ||
       ((navigator as any).msMaxTouchPoints ?? 0 > 0));
  }

export const InternalNavigationMenu = ({components}: {components: MenuItemComponentProps[]}) => {
    return (
        <NavigationMenu orientation="horizontal">
            <NavigationMenuList>
                {components.map((component: MenuItemComponentProps) => (
                    <NavigationMenuItem key={component.menuGroupName}>
                        <NavigationMenuTrigger
                            onClick={(event) => {
                                if (isTouchDevice()) {
                                    return;
                                }

                                event.preventDefault();
                            }}
                        >{component.menuGroupName}</NavigationMenuTrigger>
                        <NavigationMenuContent>
                            <ul className="grid md:grid-cols-1 w-[600px] gap-3 p-2 ">
                                {component.menuGroupItems.map((menuGroupItem: {title: string, href: string, description: string}) => (
                                    <ListItem
                                        key={menuGroupItem.title}
                                        title={menuGroupItem.title}
                                        href={menuGroupItem.href} 
                                        className={""}>
                                            {menuGroupItem.description}
                                    </ListItem>
                                ))}
                            </ul>
                        </NavigationMenuContent>
                    </NavigationMenuItem>
                ))}
            </NavigationMenuList>
        </NavigationMenu>
    )
}

const ListItem = (({ className, title, children, href }: { className: string, title: string, children: React.ReactNode, href: string }) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <Link
                    href={href} 
                    className={cn(
                        "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        className
                    )}>
                    <div className="text-sm font-medium leading-none">{title}</div>
                    <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
                        {children}
                    </p>
                </Link>
            </NavigationMenuLink>
        </li>
    )
})