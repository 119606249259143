import { Button } from "@/components/ui/button";
import { ExtendedSubscriptionTemplate, ExtendedTeam, ExtendedUser, NewUser } from "@/prisma/types";

interface UpgradeReceiptProps {
    newUsers: NewUser[];
    currentUser: ExtendedUser;
    team: ExtendedTeam;
    allSubscriptionTemplates: ExtendedSubscriptionTemplate[];
    buttonClicked: () => void;
}

export const UpgradeReceiptComponent = ({ newUsers, currentUser, team, allSubscriptionTemplates, buttonClicked }: UpgradeReceiptProps) => {



    return (
        <div className="flex flex-col max-w-xl items-center py-10 h-full w-full">


            <h2 className="font-recoleta text-black text-3xl pt-1 mb-4">Thank You</h2>

            {newUsers.length === 1 && (
                <p className="text-black text-center">You now have access to CM Navigator</p>
            ) || (
                    <p className="text-black text-center">You and your team now have access to CM Navigator</p>
                )
            }

            <p className="text-black text-center pt-2">
                You can now start using CM Navigator, and invite more users to your team at any time. If you have any questions, please contact us at <a href="mailto:contact@cmnavigator.com" className="underline">contact@cmnavigator.com</a>
            </p>

            <div className="flex flex-row w-full pt-10">
                <Button
                    disabled={newUsers === null || newUsers?.length === 0}
                    size={"lg"}
                    className="w-full min-w-[200px]"
                    onClick={buttonClicked}>
                    Get Started
                </Button>
            </div>
        </div>

    )
}