
"use client"

import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";

import { MinusCircle, Plus } from "lucide-react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { cn } from "@/lib/utils";
import { ExtendedSubscriptionTemplate, ExtendedTeam, ExtendedUser, NewUser, TeamSubscriptionPrice } from "@/prisma/types";
import { calculateMonthlyPrice, getMostExpensiveSubscription } from "@/lib/price-utils";
import { v4 as uuidv4 } from 'uuid';

interface InviteTeamMembersFormProps {
    className?: string;
    currentUser: ExtendedUser;
    team: ExtendedTeam;
    allSubscriptionTemplates: ExtendedSubscriptionTemplate[];
    teamSubscriptionPrices: TeamSubscriptionPrice[]
    confirmSubscriptionPlan: (users: NewUser[]) => void;
}

export const InviteTeamMembersForm = ({ className, currentUser, team, allSubscriptionTemplates, teamSubscriptionPrices, confirmSubscriptionPlan }: InviteTeamMembersFormProps) => {
    // const sortedSubscriptionTemplates = allSubscriptionTemplates.sort((a, b) => (b.defaultSubscriptionPrices?.[0]?.basePrice ?? 0) - (a.defaultSubscriptionPrices?.[0]?.basePrice ?? 0))

    const [newUsers, setNewUsers] = useState<NewUser[]>([])

    const [totalPrice, setTotalPrice] = useState<number | undefined>(undefined)

    useEffect(() => {
        const mostExpensiveTemplate = getMostExpensiveSubscription(allSubscriptionTemplates)

        if (mostExpensiveTemplate === null || mostExpensiveTemplate === undefined) {
            return 
        }

        setNewUsers([{
            id: uuidv4(),
            email: currentUser.email,
            subscriptionTemplate: mostExpensiveTemplate
        }])
    }, []);


    useEffect(() => {
        updatePrice()
    }, [newUsers])

    const updateEmail = (userId: string, value: string) => {
    
        // Create a new array by mapping over the existing users array
        const updatedUsers = newUsers.map((user, i) => {
            if (user.id === userId ) {
                // Return a new object with the updated email if the index matches
                return { ...user, email: value };
            }
            // Return the user unchanged if the index doesn't match
            return user;
        }) ?? [];

        setNewUsers(updatedUsers);  // Set the new array as the state
    }

    const getPlanText = (newUserIndex: string) => {
        const user = newUsers.find((user) => user.id === newUserIndex)

        if (!user || !user.subscriptionTemplate) {
            return "Select a plan"
        }
        return allSubscriptionTemplates.find((template) => template.id === user.subscriptionTemplate.id)?.name ?? "Select a plan"
    }

    const userPlanChanged = (userId: string, value: string) => {


        const templateId = parseInt(value)
        const template = allSubscriptionTemplates.find((template) => template.id === templateId)

        if (!template) {
            console.log("template not found")
            return;
        }

        // TODO - fix bug with weird sorting
        const updatedUsers = newUsers.map((user, i) => {
            if (user.id === userId) {
                return { ...user, subscriptionTemplate: template }
            }
            return user
        }) ?? []

        setNewUsers(updatedUsers)
    }

    const updatePrice = () => {
        console.log("update price")
        console.log("team prices", teamSubscriptionPrices)

        const totalPrice = calculateMonthlyPrice({
            teamPrices: teamSubscriptionPrices,
            newUsers: newUsers ?? [],
        })
        // const newTotalPrice = calculateUserPrices({ 
        //     newUsers: newUsers ?? [], 
        //     subscriptionTemplates: sortedSubscriptionTemplates, 
        //     team,
        //     teamSubscriptionPrices }) ?? 0
        
        setTotalPrice(totalPrice)
    }

    const addAnotherUser = () => {
        // use the first user's subscription template as default
        const defaultSubscription = newUsers?.[0].subscriptionTemplate

        // const newId = newUsers?.length ?? 0

        const users = [
            ...newUsers ?? [],
            {
                id: uuidv4(),
                email: "",
                subscriptionTemplate: defaultSubscription
            }
        ] as NewUser[]

        setNewUsers(users ?? [])
    }

    const removeInvitation = (userId: string) => {
        const asNewUsers = newUsers?.filter((user) => user.id !== userId) ?? []

        setNewUsers(asNewUsers)
    }

    const handleSubmit = () => {
        console.log("handle submit ", newUsers)
        if (newUsers === null || newUsers === undefined || newUsers.length === 0) {
            return;
        }

        //calculateUserPrices({ newUsers, subscriptionTemplates: sortedSubscriptionTemplates, team, teamSubscriptionPrices })

        confirmSubscriptionPlan(newUsers)
    }

    return (
        <div className="w-full">

            <div className="flex flex-col">
                <div className="flex flex-col gap-8">

                    {newUsers?.map((user, index) => (
                        <div key={user.id} className="flex flex-row items-center justify-between w-full">
                            <div className="flex flex-col gap-6 w-full pr-4">
                                <Input 
                                    placeholder="Email" 
                                    defaultValue={user?.email ?? ""} 
                                    onChange={(event) => {
                                        updateEmail(user.id, event.target.value)
                                    }}/>
                                {/* <h3 className="text-black">{user.email}</h3> */}
                            </div>
                            <Select onValueChange={(value) => userPlanChanged(user.id, value)}>
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder={getPlanText(user.id)} />
                                </SelectTrigger>
                                <SelectContent>
                                    {allSubscriptionTemplates.map((template) => (
                                        <SelectItem key={template.id} value={"" + template.id}>{template.name}</SelectItem>
                                    ))}

                                </SelectContent>
                            </Select>

                            <Button
                                className={cn("", index === 0 ? "invisible" : "")}
                                variant={"lightText"}
                                size={"snug"}
                                onClick={() => removeInvitation(user.id)}>
                                <MinusCircle className="h-4 w-4 text-neutral-700 ml-4" />
                            </Button>

                        </div>
                    ))}
                </div>
                <div className="flex flex-row w-full items-center justify-start mt-6">

                    <Button
                        variant={"lightText"}
                        size={"snug"}
                        onClick={addAnotherUser}>
                        <Plus className="h-6 w-6 text-black mr-2" /> Add More
                    </Button>
                </div>
                <div className="w-full flex flex-col justify-end items-end pt-6 pb-2">
                    <p className="text-muted-foreground text-xs mb-2">Total price per month</p>
                    <h1 className="font-recoleta text-4xl text-black">{totalPrice} €</h1>
                    <Button
                        disabled={newUsers === null || newUsers?.length === 0}
                        className="mt-4"
                        onClick={handleSubmit}>
                        Confirm Upgrade
                    </Button>
                </div>

            </div>
            {/* <div>
                {filteredTemplates.map((template) => (
                    <div key={template.id} className="flex flex-row  gap-8 items-center justify-between w-full bg-white p-4 rounded-lg shadow-md mb-4">
                        <div className="flex flex-col gap-6 ">
                            <h3 className="text-black">{template.name}</h3>
                        </div>

                        {template.defaultSubscriptionPrices?.map((price) => (
                            <div className="flex flex-col">
                                <p className="text-black">{price.basePrice + price.userPrice}</p>
                                <p className="text-black">{price.userPrice}</p>
                            </div>
                        ))}

                    </div>
                ))}
            </div> */}
        </div>

    )
}
